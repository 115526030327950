import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=2fcf2034&"
import script from "./Error404.vue?vue&type=script&lang=js&"
export * from "./Error404.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorGame: require('/builds/reifen_gundlach/frontend/components/molecules/ErrorGame/ErrorGame.vue').default,Button: require('/builds/reifen_gundlach/frontend/components/atoms/Button/Button.vue').default})
