//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  name: 'Error404',
  props: {
    headline: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    personalHighscoreHeadline: {
      type: String,
      default: ''
    },
    globalHighscoreHeadline: {
      type: String,
      default: ''
    },
    errorTextLoggedOut: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { currentLanguage } = useCurrentLanguage()

    return { currentLanguage }
  }
})
