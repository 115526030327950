//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref, computed, watchEffect } from '@nuxtjs/composition-api'
import axios from 'axios'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useLoginInformation from '~/composables/useLoginInformation'
import useSettingsStore from '~/composables/useSettingsStore'

export default defineComponent({
  name: 'ErrorGame',
  props: {
    personalHighscoreHeadline: {
      type: String,
      default: ''
    },
    globalHighscoreHeadline: {
      type: String,
      default: ''
    },
    textLoggedOut: {
      type: String,
      default: ''
    }
  },
  setup () {
    const currentScore = ref(0)
    const actionName = 'ebp.game.highscore'
    const { actions, setActionValue } = useSettingsStore(actionName)
    const { userIsAuthenticated } = useLoginInformation()
    const proxyShopUrl = '/proxy/searchstore'
    const globalHighScores = ref([])
    const { faroError } = useFaroIntegration()

    onMounted(() => {
      document.onkeydown = function (evt) {
        evt = evt || window.event
        if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown' || evt.code === 'Space') {
          const box = document.getElementById('messageBox')
          box.style.visibility = 'hidden'
          evt.preventDefault()
        }
      }

      if (userIsAuthenticated.value) {
        getGlobalHighscores()
      } else {
        const unwatch = watchEffect(() => {
          if (userIsAuthenticated.value) {
            getGlobalHighscores()
            unwatch()
          }
        })
      }
    })

    async function getGlobalHighscores () {
      try {
        const result = await axios.get(`${proxyShopUrl}/game/highscores`)
        globalHighScores.value = result.data.highscores.entries
      } catch (err) {
        faroError(err, 'searchstore-gameHighscore')
      }
    }

    const highScore = computed(() => {
      if (userIsAuthenticated.value && actions[actionName].value) {
        return actions[actionName].value
      }
      return 0
    })

    const personalRank = computed(() => {
      if (highScore.value && globalHighScores.value) {
        const rank = globalHighScores.value.find(gscore => gscore.score === parseInt(highScore.value))
        if (rank) {
          return ({
            ...rank,
            index: globalHighScores.value.indexOf(rank)
          })
        }
      }
      return null
    })

    window.addEventListener('game_over', (e) => {
      currentScore.value = e.detail.score
      setHighScore()
    })

    async function setHighScore () {
      if (userIsAuthenticated.value) {
        if (actions[actionName].value === undefined || actions[actionName].value === null) {
          setActionValue(currentScore.value)
        } else if (currentScore.value > actions[actionName].value) {
          setActionValue(currentScore.value)
        }
        await axios.put(`${proxyShopUrl}/game/highscores/${currentScore.value}`)
        getGlobalHighscores()
      }
    }

    return { highScore, userIsAuthenticated, globalHighScores, personalRank }
  },
  head () {
    return {
      link: [
        { rel: 'stylesheet', href: '/404_game/index.css' }
      ],
      script: [
        { src: '/404_game/index.js' }
      ]
    }
  }
})
